/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import { subclass as t } from "./accessorSupport/decorators/subclass.js";
let r = 0;
const s = s => {
    let i = class extends s {
      constructor(...e) {
        super(...e), Object.defineProperty(this, "uid", {
          writable: !1,
          configurable: !1,
          value: Date.now().toString(16) + "-object-" + r++
        });
      }
    };
    return i = e([t("esri.core.Identifiable")], i), i;
  },
  i = s => {
    let i = class extends s {
      constructor(...e) {
        super(...e), Object.defineProperty(this, "uid", {
          writable: !1,
          configurable: !1,
          value: r++
        });
      }
    };
    return i = e([t("esri.core.Identifiable.NumericIdentifiable")], i), i;
  };
let o = class extends s(class {}) {};
o = e([t("esri.core.Identifiable")], o);
export { o as Identifiable, s as IdentifiableMixin, i as NumericIdentifiableMixin };